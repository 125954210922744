import {
  ATHLETE_SUCCESS,
  ATHLETE_VIEW,
  CLEAR_MESSAGE,
  START_LOADING,
  STOP_LOADING,
  ATHLETE_COUNT,
  ATHLETE_RANKING,
  DATA_FAILED,
  ATHLETE_LIST,
  SEARCH_FIELD,
  STATE_FIELD,
  CITY_FIELD,
  CURRENT_PAGE,
  ATHLETE_TROPHY_LIST,
  TROPHY_TOURNAMENT_LIST,
} from '../actions/Types';

const initialState = {
  athletes: [],
  currentPage: 1,
  perPageAthletes: 25,
  totalAthletes: 1,
  athleteList: [],
  details: {},
  message: null,
  isError: false,
  isLoading: false,
  countingData: {},
  rankingData: null,
  trophyList: [],
  trophyTournamentList: [],
};
export default function Athletes(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case ATHLETE_SUCCESS:
      return {
        ...state,
        athletes: payload.athletes.sort((a, b) => a.user_id - b.user_id),
      };
    case ATHLETE_LIST:
      return {
        ...state,
        athleteList: payload.athleteList.sort((a, b) => a.user_id - b.user_id),
        currentPage: payload.currentPage,
        totalAthletes: payload.totalAthletes,
      };
    case SEARCH_FIELD:
      return {
        ...state,
        query: payload.query,
      };
    case STATE_FIELD:
      return {
        ...state,
        state: payload.state,
      };
    case CITY_FIELD:
      return {
        ...state,
        city: payload.city,
      };
    case CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload.currentPage,
      };

    case DATA_FAILED:
      return {
        ...state,
        message: payload.message,
      };
    case ATHLETE_VIEW:
      return {
        ...state,
        details: payload.details,
      };

    case ATHLETE_COUNT:
      return {
        ...state,
        countingData: payload.countingData,
      };

    case ATHLETE_TROPHY_LIST:
      return {
        ...state,
        trophyList: payload.trophyList,
      };

    case TROPHY_TOURNAMENT_LIST:
      return {
        ...state,
        trophyTournamentList: payload.trophyTournamentList,
      };
    case ATHLETE_RANKING:
      return {
        ...state,
        rankingData: payload.rankingData,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        isError: false,
      };
    default:
      return state;
  }
}
