import Beginner from '../../../../assets/images/badges/Beginner.png';

import Bronze1 from '../../../../assets/images/badges/Bronze1.png';
import Bronze2 from '../../../../assets/images/badges/Bronze2.png';
import Bronze3 from '../../../../assets/images/badges/Bronze3.png';
import Bronze4 from '../../../../assets/images/badges/Bronze4.png';

import Silver1 from '../../../../assets/images/badges/Silver1.png';
import Silver2 from '../../../../assets/images/badges/Silver2.png';
import Silver3 from '../../../../assets/images/badges/Silver3.png';
import Silver4 from '../../../../assets/images/badges/Silver4.png';

import Gold1 from '../../../../assets/images/badges/Gold1.png';
import Gold2 from '../../../../assets/images/badges/Gold2.png';
import Gold3 from '../../../../assets/images/badges/Gold3.png';
import Gold4 from '../../../../assets/images/badges/Gold4.png';

import Platinum1 from '../../../../assets/images/badges/Platinum1.png';
import Platinum2 from '../../../../assets/images/badges/Platinum2.png';
import Platinum3 from '../../../../assets/images/badges/Platinum3.png';
import Platinum4 from '../../../../assets/images/badges/Platinum4.png';

//Trophy Room
import Trophyroom from '../../../../assets/images/badges/box25.png';
import Goldcup from '../../../../assets/images/badges/Goldcup.png';
import Silvercup from '../../../../assets/images/badges/Silvercup.png';
import Bronzecup from '../../../../assets/images/badges/Bronzecup.png';

// Category
import CategoryBlock from '../../../../assets/images/badges/CategoryBlock.png';
import Categorybar from '../../../../assets/images/badges/Categorybar.png';
import Category from '../../../../assets/images/badges/Category.png';
import Ribbon1 from '../../../../assets/images/badges/Ribbon1.png';

//Trophy List
import Novice_bronze from '../../../../assets/images/trophys/Novice_bronze.png';
import Novice_silver from '../../../../assets/images/trophys/Novice_silver.png';
import Novice_gold from '../../../../assets/images/trophys/Novice_gold.png';

import Beginner_bronze from '../../../../assets/images/trophys/Beginner_bronze.png';
import Beginner_silver from '../../../../assets/images/trophys/Beginner_silver.png';
import Beginner_gold from '../../../../assets/images/trophys/Beginner_gold.png';

import AmatureC_bronze from '../../../../assets/images/trophys/AmatureC_bronze.png';
import AmatureC_silver from '../../../../assets/images/trophys/AmatureC_silver.png';
import AmatureC_gold from '../../../../assets/images/trophys/AmatureC_gold.png';

import AmatureB_bronze from '../../../../assets/images/trophys/AmatureB_bronze.png';
import AmatureB_silver from '../../../../assets/images/trophys/AmatureB_silver.png';
import AmatureB_gold from '../../../../assets/images/trophys/AmatureB_gold.png';

import AmatureA_bronze from '../../../../assets/images/trophys/AmatureA_bronze.png';
import AmatureA_silver from '../../../../assets/images/trophys/AmatureA_silver.png';
import AmatureA_gold from '../../../../assets/images/trophys/AmatureA_gold.png';

import Qualify_bronze from '../../../../assets/images/trophys/Qualify_bronze.png';
import Qualify_silver from '../../../../assets/images/trophys/Qualify_silver.png';
import Qualify_gold from '../../../../assets/images/trophys/Qualify_gold.png';

import Pro_bronze from '../../../../assets/images/trophys/Pro_bronze.png';
import Pro_silver from '../../../../assets/images/trophys/Pro_silver.png';
import Pro_gold from '../../../../assets/images/trophys/Pro_gold.png';

export const BadgeImages = {
  Beginner: Beginner,
  Bronze1: Bronze1,
  Bronze2: Bronze2,
  Bronze3: Bronze3,
  Bronze4: Bronze4,
  Silver1: Silver1,
  Silver2: Silver2,
  Silver3: Silver3,
  Silver4: Silver4,
  Gold1: Gold1,
  Gold2: Gold2,
  Gold3: Gold3,
  Gold4: Gold4,
  Platinum1: Platinum1,
  Platinum2: Platinum2,
  Platinum3: Platinum3,
  Platinum4: Platinum4,

  //Trophy Room
  Trophyroom: Trophyroom,
  Goldcup: Goldcup,
  Silvercup: Silvercup,
  Bronzecup: Bronzecup,

  // Category
  CategoryBlock: CategoryBlock,
  Categorybar: Categorybar,
  Category: Category,
  Ribbon1: Ribbon1,
};

export const Images = {
  //Trophy List
  Novice_bronze: Novice_bronze,
  Novice_silver: Novice_silver,
  Novice_gold: Novice_gold,
  Beginner_bronze: Beginner_bronze,
  Beginner_silver: Beginner_silver,
  Beginner_gold: Beginner_gold,
  AmatureC_bronze: AmatureC_bronze,
  AmatureC_silver: AmatureC_silver,
  AmatureC_gold: AmatureC_gold,
  AmatureB_bronze: AmatureB_bronze,
  AmatureB_silver: AmatureB_silver,
  AmatureB_gold: AmatureB_gold,
  AmatureA_bronze: AmatureA_bronze,
  AmatureA_silver: AmatureA_silver,
  AmatureA_gold: AmatureA_gold,
  Qualify_bronze: Qualify_bronze,
  Qualify_silver: Qualify_silver,
  Qualify_gold: Qualify_gold,
  Pro_bronze: Pro_bronze,
  Pro_silver: Pro_silver,
  Pro_gold: Pro_gold,
};
