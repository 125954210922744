import './index.scss';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import noImg from '../../../../assets/images/no-image.jpg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getArenaTournamentList } from '../../../../actions/Cms';
import moment from 'moment';
import { ChampionCardPlaceholder, ProfileCardPlaceholder } from '../../../../components/skeleton-placeholder';

const ChampionshipView = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { arena_tournament_list, arena_details, isLoading } = useSelector(state => state.Cms);

    console.log(arena_tournament_list, arena_details)

    useEffect(() => {
        dispatch(getArenaTournamentList(id));
    }, [dispatch]);

    return (
        <div className='arenaBase pt-0'>
            <div className='bannerChapions'>
                <div className='maleBeginner'>
                    <div className='container'>
                        {isLoading ? <ProfileCardPlaceholder /> :
                            <div className='maleFlex'>
                                <div className='maleLft'>
                                    <Link className='back-btn' to="/championships"><i className="fa-solid fa-chevron-left"></i> </Link>
                                    <div className='mlCard ps-3'>
                                        <div className='mlCardImg'>
                                            {arena_details?.profile?.profile_url ?
                                                <img className='img-user' src={arena_details?.profile?.profile_url} alt={arena_details?.name} />
                                                : <img className='img-user' src={noImg} alt="No user" />}
                                        </div>
                                        <div className='maleBeginnr'>
                                            <h4>{arena_details?.name ? arena_details?.name : "N/A"}</h4>
                                            <h5>{arena_details?.address ? arena_details?.address : "N/A"}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='cmn-content-body championship-details-wrapper'>
                <div className='container'>
                    <div className="championCardBg row">
                        {isLoading ? <ChampionCardPlaceholder number={12} /> :
                            arena_tournament_list && arena_tournament_list.length > 0 ? arena_tournament_list.map((item, index) => {
                                return (
                                    <>
                                        <div key={item?.id} className="col-lg-4 col-md-6 py-2">
                                            <div className={`championCard ${item?.status === 2 ? 'champCardActive' : ''}`}
                                                onClick={() => {
                                                    if (item?.type?.type_name === "Group Phase") navigate(`/championships/view/group-phase/${item.id}`);
                                                    if (item?.type?.type_name === "Double Elimination") navigate(`/championships/view/double-elimination/${item.id}`);
                                                }}
                                            >
                                                <div className="chamCardTop">
                                                    <div className="champDate">
                                                        <h5>{moment(item.from_date).format('DD')}</h5>
                                                        <span>{moment(item.from_date).format('MMM')}</span>
                                                    </div>
                                                    <div className="champTxt">
                                                        <h5>{item?.championship_name}</h5>
                                                        <i className="fa-light fa-user-group"></i> {item?.duo?.duo_number} {t("Teams")}
                                                    </div>
                                                </div>
                                                <div className="chamCardBtm">
                                                    {item?.status === 1 && <span>{t("Not started")}  <i className="fa-solid fa-toggle-large-off"></i></span>}
                                                    {item?.status === 2 && <span className='in-progress'>{t("In progress")}  <i className="fa-regular fa-toggle-large-on"></i></span>}
                                                    {item?.status === 3 && <span className='completed'>{t("Completed")}  <i className="fa-regular fa-toggle-large-off"></i></span>}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }) : <p className='text-center mb-0'>{t("Tournaments")} {t("Not Found")}.</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChampionshipView;