import {
    CATEGORIES_SUCCESS,
    CLEAR_ALL_NOTIFICATION,
    CLEAR_MESSAGE,
    DATA_FAILED,
    DUOS_SUCCESS,
    GET_BANNER,
    NOTIFICATIONS_SUCCESS,
    RECIEVED_NOTIFICATION,
    START_LOADING,
    STOP_LOADING,
    TEAM_SUCCESS,
    TYPES_SUCCESS
} from "../actions/Types";

const initialState = {
    duoslist: [],
    categorieslist: [],
    typeslist: [],
    teamslist: [],
    notificationlist: [],
    bannerlist: [],
    message: null,
    isError: false,
    isLoading: false
}
export default function CommonData(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DUOS_SUCCESS:
            return {
                ...state,
                duoslist: payload.duoslist.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            }
        case CATEGORIES_SUCCESS:
            return {
                ...state,
                categorieslist: payload.categorieslist.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            }
        case TYPES_SUCCESS:
            return {
                ...state,
                typeslist: payload.typeslist.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            }
        case TEAM_SUCCESS:
            return {
                ...state,
                teamslist: payload.teamslist.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            }
        case NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notificationlist: payload.notificationlist.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            }
        case RECIEVED_NOTIFICATION:
            const newNotification = [...state.notificationlist, payload.notification];
            newNotification.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            return {
                ...state,
                notificationlist: newNotification,
            };
        case CLEAR_ALL_NOTIFICATION:
            return {
                ...state,
                notificationlist: [],
            };
        case GET_BANNER:
            return {
                ...state,
                bannerlist: payload.bannerlist || [],
            }
        case DATA_FAILED:
            return {
                ...state,
                message: payload.message
            }
        case START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: null,
                isError: false
            };
        default:
            return state;
    }
}