import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Nav, Tab, Tabs} from 'react-bootstrap';
import MatchesBracket from './matches-bracket';
import {
  fetchDoubleEliminationBracketList,
  fetchDoubleEliminationFinalList,
  fetchTournamentView,
  tournamentWinnerAction,
} from '../../../../../actions/Tournaments';
import Loader from '../../../../../components/loader';
import FinalStage from '../components/final-matches-bracket';
import BannerHeader from '../components/banner-header';
import Podium from '../../../../../components/podium';

const DoubleElimination = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {id} = useParams();

  const {
    tournament,
    tournamentMatch,
    isLoading,
    winningStages,
    losingStages,
    finalMatches,
  } = useSelector(state => state.Tournaments);

  useEffect(() => {
    dispatch(fetchDoubleEliminationBracketList(id));
    dispatch(fetchDoubleEliminationFinalList(id));
    dispatch(fetchTournamentView(id));
    dispatch(tournamentWinnerAction(id));
  }, []);

  console.log('tournament===>', tournament);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <BannerHeader
            arenaId={tournament?.arena_id}
            tournamentId={tournament?.id}
            picUrl={tournament?.pic_url}
            tournamentName={tournament?.championship_name}
            duoNumber={tournament?.duo?.duo_number}
            categoryType={tournament?.category?.category_name}
            status={tournament?.status}
          />

          <div className="group-phase-tabs double-elimination-tabs">
            <Tab.Container defaultActiveKey="prematches" transition={false}>
              <Nav className="cmn-tabs-nav">
                <Nav.Item>
                  <Nav.Link eventKey="prematches">{t('Pre Matches')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="final">Final</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Podium tournament={tournament} />
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="prematches" title={t('Pre Matches')}>
                  {tournamentMatch && tournamentMatch?.length > 0 ? (
                    <>
                      <div className="match-flow-scrollbar">
                        <MatchesBracket
                          category={'winner'}
                          totalTeam={tournament?.duo?.duo_number}
                          tournamentId={id}
                          tournamentType={tournament?.type?.type_name}
                          tournamentStatus={tournament?.status}
                          stages={winningStages}
                          data={tournamentMatch?.filter(
                            item => item.winners_side == 0,
                          )}
                          totalMatches={tournamentMatch}
                        />
                      </div>
                      <h3 className="mt-4 px-3 admin-page-title">
                        {t('Losing Team')}
                      </h3>
                      <div className="match-flow-scrollbar">
                        <MatchesBracket
                          category={'looser'}
                          totalTeam={tournament?.duo?.duo_number}
                          tournamentId={id}
                          tournamentType={tournament?.type?.type_name}
                          tournamentStatus={tournament?.status}
                          stages={losingStages}
                          data={tournamentMatch?.filter(
                            item => item.winners_side == 1,
                          )}
                          totalMatches={tournamentMatch}
                        />
                      </div>
                    </>
                  ) : (
                    <p className="mt-3">
                      {'Matches'} {'Not Found'}.
                    </p>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey="final" title="Final">
                  {finalMatches && finalMatches?.length > 0 ? (
                    <div className="tf-content">
                      <FinalStage
                        finalMatches={finalMatches}
                        tournamentId={id}
                        tournamentType={tournament?.type?.type_name}
                        tournamentStatus={tournament?.status}
                        noTeamsFinal={4}
                        preMatchLength={
                          tournamentMatch?.filter(
                            item => item.winners_side != 2,
                          ).length
                        }
                      />
                    </div>
                  ) : (
                    <p className="mt-3">
                      {'Final'} {'Matches'} {'Not Found'}.
                    </p>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </>
      )}
    </>
  );
};

export default DoubleElimination;
