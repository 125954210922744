import './index.scss';
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BannerBar from '../../components/banner-bar';
import { getBanners } from '../../../../actions/CommonData';

const EventView = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();

    const { bannerlist, isLoading: isLoadingBanner } = useSelector(state => state.CommonData);

    useEffect(() => {

        dispatch(getBanners({ page: "Events" }));
    }, [dispatch]);


    return (
        <div className='arenaBase pt-0'>
            <BannerBar isLoading={isLoadingBanner} bannerData={bannerlist} />
            <div className='cmn-content-body event-details-wrapper'>
                <div className='container'>
                    Event Details content...
                </div>
            </div>
        </div>
    )
}

export default EventView;