import React from 'react';
import './index.scss';
import {CloseButton, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import silverPodiumStand from '../../assets/images/podium-stand/silver-podium-stand.svg';
import goldPodiumStand from '../../assets/images/podium-stand/gold-podium-stand.svg';
import bronzePodiumStand from '../../assets/images/podium-stand/bronze-podium-stand.svg';
import noImg from '../../assets/images/no-image.jpg';

const StagePodiumModal = ({
  openViewModal,
  setOpenViewModal,
  tournamentWinnerDetails,
}) => {
  const {t} = useTranslation();

  const TeamDetails = ({team, position, extraPoints, stand}) => {
    return (
      <>
        <div className="bg">
          <div className="team">
            <span>
              {t('Team')} {team?.team_division_id}
            </span>
          </div>
          <div className="athlete-card">
            <div className="athlete-card-img">
              {team?.athlete_player1?.profile?.profile_url ? (
                <img
                  src={team?.athlete_player1?.profile?.profile_url}
                  alt={team?.athlete_player1?.display_name}
                />
              ) : (
                <img src={noImg} alt="No image" />
              )}
            </div>
            <div className="athlete-card-body">
              <h5>
                <span className="ranking-id">#{team?.athlete_player1?.id}</span>
                {team?.athlete_player1?.display_name}
              </h5>
              <p className="ftv-points">
                {t('FTV points')}:&nbsp;
                {team?.athlete_player1?.profile?.ftv_points}&nbsp;
                <span className="player-score">(+{extraPoints})</span>
              </p>
            </div>
          </div>
          <div className="athlete-card">
            <div className="athlete-card-img">
              {team?.athlete_player2?.profile?.profile_url ? (
                <img
                  src={team?.athlete_player2?.profile?.profile_url}
                  alt={team?.athlete_player2?.display_name}
                />
              ) : (
                <img src={noImg} alt="No image" />
              )}
            </div>
            <div className="athlete-card-body">
              <h5>
                <span className="ranking-id">#{team?.athlete_player2?.id}</span>
                {team?.athlete_player2?.display_name}
              </h5>
              <p className="ftv-points">
                {t('FTV points')}:&nbsp;
                {team?.athlete_player2?.profile?.ftv_points}&nbsp;
                <span className="player-score">(+{extraPoints})</span>
              </p>
            </div>
          </div>
        </div>
        <div className='podium-stand-bx'>
          <div className="win-position">{position}</div>
          <img className="w-100 podium-stand-img" src={stand} />
        </div>
      </>
    );
  };

  return (
    <Modal
      show={openViewModal}
      onHide={() => setOpenViewModal(false)}
      keyboard={false}
      size="lg"
      className="game-popup podium-modal-style"
      centered>
      <Modal.Body className="radius-bl-br-10">
        <CloseButton
          variant="white"
          onClick={() => setOpenViewModal(false)}
          className=""
        />
        <div className="podium-content">
          <div className="game-popup-player silver-bg">
            <TeamDetails
              position={2}
              extraPoints={tournamentWinnerDetails?.category?.second}
              team={tournamentWinnerDetails?.first_runnerup_team}
              stand={silverPodiumStand}
            />
          </div>
          <div className="game-popup-player gold-bg">
            <TeamDetails
              position={1}
              extraPoints={tournamentWinnerDetails?.category?.first}
              team={tournamentWinnerDetails?.winner_team}
              stand={goldPodiumStand}
            />
          </div>
          <div className="game-popup-player bronze-bg">
            <TeamDetails
              position={3}
              extraPoints={tournamentWinnerDetails?.category?.third}
              team={tournamentWinnerDetails?.second_runnerup_team}
              stand={bronzePodiumStand}
            />
          </div>
        </div>
        {/* <img className="w-100 podium-stand-img" src={podiumStand} /> */}
      </Modal.Body>
    </Modal>
  );
};

export default StagePodiumModal;
