import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom';
import {getTrophyTournamentList} from '../../../../actions/Athletes';
import _ from 'lodash';

import noImg from '../../../../assets/images/no-image.jpg';
import { TournamentListPlaceholder, TrophyTournamentListPlaceholder } from '../../../../components/skeleton-placeholder';

const TrophyTournament = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {isLoading, trophyTournamentList} = useSelector(
    state => state.Athletes,
  );
  useEffect(() => {
    const payload = {
      id: id,
      categories_id: location.state?.categories_id,
      type: location.state?.type,
    };
    dispatch(getTrophyTournamentList(payload));
  }, []);


  const TournamentItems =
  trophyTournamentList && trophyTournamentList.length > 0 ? (
    trophyTournamentList.map(item => (
      <tr key={item?.id}>
        <td data-label={t('Tournament Name')}>
          {item?.championship_name ? item.championship_name : 'N/A'}
        </td>
        <td data-label={t('Tournament Type')}>
          {item?.type_name ? t(item?.type_name) : 'N/A'}
        </td>
        <td data-label={t('From Date')}>
          {item?.from_date
            ? moment(item.from_date).format('DD-MM-YYYY')
            : 'N/A'}
        </td>
        <td data-label={t('From Time')}>
          {item?.from_time
            ? moment(item.from_time, 'HH:mm').format('HH:mm')
            : 'N/A'}
        </td>       
        <td data-label={t('Action')} className="arenaBtns">
            <button
              type="button"
              onClick={() => {
                if (item.types_id == '2')
                  navigate(`/championships/view/group-phase/${item.id}`);
                if (item.types_id == '1')
                  navigate(
                    `/championships/view/double-elimination/${item.id}`,
                  );
              }}>
              <i className="fa-regular fa-eye"></i>
            </button>
       
        </td>
      </tr>
    ))
  ) : (
    <tr className="p-0">
      <td colSpan="5" className="text-center">
        {t('Tournaments')} {t('Not Found')}.
      </td>
    </tr>
  );

  return (
    <div className="arenaBase">
      <h2 className="admin-page-title mb-3">
        {t(location.state.category_name)}
      </h2>
      <table className="mb-3">
          <thead>
            <tr>
              <th scope="col">{t('Tournament Name')}</th>
              <th scope="col">{t('Tournament Type')}</th>
              <th scope="col">{t('From Date')}</th>
              <th scope="col">{t('From Time')}</th>
              <th scope="col">{t('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? <TrophyTournamentListPlaceholder /> : TournamentItems}
          </tbody>
        </table>
    </div>
  );
};

export default TrophyTournament;
