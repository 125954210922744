import './index.scss';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import StagePodiumModal from './StagePodiumModal';
import {useSelector} from 'react-redux';
const Podium = ({tournament}) => {
  const {t} = useTranslation();
  const [openViewModal, setOpenViewModal] = useState(false);

  console.log('tournament', tournament);
  const {tournamentWinnerDetails} = useSelector(state => state.Tournaments);

  return (
    <>
      {tournamentWinnerDetails?.winner && (
        <button className="podium-btn" onClick={() => setOpenViewModal(true)}>
          {t('Podium')} <i className="bi bi-trophy-fill"></i>
        </button>
      )}
      <StagePodiumModal
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        tournamentWinnerDetails={tournamentWinnerDetails}
      />
    </>
  );
};

export default Podium;
