import './index.scss';
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import noUser from '../../../../assets/images/no-user.jpg';
import imgShape from '../../../../assets/images/ShieldBadgeBlack.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/loader';
import { getArenasView } from '../../../../actions/Cms';
import BannerBar from '../../components/banner-bar';
import OpeningHoursComponent from '../../../../components/opening-hours-dropdown';
import { getBanners } from '../../../../actions/CommonData';

const ArenaDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { arena_details, isLoading: isLoadingArenaDetails } = useSelector(state => state.Cms);
    const { bannerlist, isLoading: isLoadingBanner } = useSelector(state => state.CommonData);

    useEffect(() => {
        dispatch(getArenasView(id));
        dispatch(getBanners({page: "Arenas"}));
    }, [dispatch]);

    // console.log("Arena details=====>", arena_details)
  
    return (
        <div className='arenaBase pt-0'>
            <BannerBar isLoading={isLoadingBanner} bannerData={bannerlist} />
            {isLoadingArenaDetails ? <Loader /> :
                <div className='cmn-content-body areans-details-wrapper'>
                    <div className='container'>
                        <div className='cmn-details-body'>
                            <div className='cmn-img-content'>
                                <div className='cmn-profile-style'>
                                    {arena_details?.profile?.profile_url ?
                                        <img className='img-user' src={arena_details?.profile?.profile_url} alt={arena_details?.name} />
                                        : <img className='img-user' src={noUser} alt="No user" />}
                                    <img className='img-shape' src={imgShape} alt="No user" />
                                </div>
                                <h6>{arena_details?.display_name ? arena_details.display_name : "N/A"}</h6>
                            </div>
                            <div className='adbTxt'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>{t("Arena Name")}</th>
                                            <td>{arena_details?.name ? arena_details.name : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("Email Address")}</th>
                                            <td>{arena_details?.email ? arena_details?.email : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("Phone Number")}</th>
                                            <td>{arena_details?.phone ? "+" + arena_details?.phone : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("Opening Hours")}</th>
                                            <td>
                                                {arena_details?.opening_hours?.length > 0 ? <OpeningHoursComponent openingHours={arena_details?.opening_hours} /> : "N/A"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("City")}</th>
                                            <td>{arena_details?.profile?.city ? arena_details.profile?.city : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("State")}</th>
                                            <td>{arena_details?.profile?.state ? arena_details?.profile?.state : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("Address")}</th>
                                            <td>{arena_details?.address ? arena_details.address : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("Championship Created")}</th>
                                            <td><span className='circleabd'>{arena_details?.profile?.championship_assigned ? arena_details?.profile?.championship_assigned : "0"}</span></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ArenaDetails;