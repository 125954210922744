import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Images } from '../view/BadgeImage';
import { getAthleteTrophyList } from '../../../../actions/Athletes';
import _ from 'lodash';

const TrophyRoom = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, trophyList } = useSelector(state => state.Athletes);
  const trophyDataList = _.sortBy(trophyList, 'categories_id');

  const imageFunc = (categoryId, type) => {
    if (categoryId == 1 && type == 'winner') return Images.Novice_gold;
    else if (categoryId == 1 && type == 'first_runnerup')
      return Images.Novice_silver;
    else if (categoryId == 1 && type == 'second_runnerup')
      return Images.Novice_bronze;
    else if (categoryId == 2 && type == 'winner') return Images.Beginner_gold;
    else if (categoryId == 2 && type == 'first_runnerup')
      return Images.Beginner_silver;
    else if (categoryId == 2 && type == 'second_runnerup')
      return Images.Beginner_bronze;
    else if (categoryId == 3 && type == 'winner') return Images.AmatureC_gold;
    else if (categoryId == 3 && type == 'first_runnerup')
      return Images.AmatureC_silver;
    else if (categoryId == 3 && type == 'second_runnerup')
      return Images.AmatureC_bronze;
    else if (categoryId == 4 && type == 'winner') return Images.AmatureB_gold;
    else if (categoryId == 4 && type == 'first_runnerup')
      return Images.AmatureB_silver;
    else if (categoryId == 4 && type == 'second_runnerup')
      return Images.AmatureB_bronze;
    else if (categoryId == 5 && type == 'winner') return Images.AmatureA_gold;
    else if (categoryId == 5 && type == 'first_runnerup')
      return Images.AmatureA_silver;
    else if (categoryId == 5 && type == 'second_runnerup')
      return Images.AmatureA_bronze;
    else if (categoryId == 6 && type == 'winner') return Images.Qualify_gold;
    else if (categoryId == 6 && type == 'first_runnerup')
      return Images.Qualify_silver;
    else if (categoryId == 6 && type == 'second_runnerup')
      return Images.Qualify_bronze;
    else if (categoryId == 7 && type == 'winner') return Images.Pro_gold;
    else if (categoryId == 7 && type == 'first_runnerup')
      return Images.Pro_silver;
    else if (categoryId == 7 && type == 'second_runnerup')
      return Images.Pro_bronze;
    else return Images.Novice_bronze;
  };

  useEffect(() => {
    dispatch(getAthleteTrophyList(id));
  }, []);
  return (
    <div className="arenaBase">
      <h2 className="admin-page-title mb-3">{t('Trophy Room')}</h2>
      <div className='trophyroom-wrap'>
        {trophyDataList.map(item => {
          return (
            <div className='trophyroom-row' key={item.categories_id}>
              <h5>{item.category_name}</h5>
              <div className='trophy-col'>
                {item.types.map((i, index) => {
                  return (
                    <div key={index} className='trophy-span'>
                      <div
                        className={`trophy-block ${i.type == "winner" && "gold-podium-stand"} ${i.type == "first_runnerup" && "silver-podium-stand"} ${i.type == "second_runnerup" && "bronze-podium-stand"}`}
                        onClick={() =>
                          navigate(`/athletes/trophy-tournament/${id}`, {
                            state: { ...item, ...i },
                          })
                        }>
                        <div className="trophy-img">
                          <img
                            src={imageFunc(item.categories_id, i.type)}
                            alt="Trophy"
                          />
                        </div>
                        <div className="trophy-count">
                          <span>{i.count}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TrophyRoom;
