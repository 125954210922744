import './index.scss';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BannerBar from '../components/banner-bar';
import { getBanners } from '../../../actions/CommonData';

const Events = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { bannerlist, isLoading: isLoadingBanner } = useSelector(state => state.CommonData);

  useEffect(() => {
    dispatch(getBanners({ page: "Events" }));
  }, [dispatch]);

  return (
    <>
      <div className="mainBase pt-0">
        <BannerBar isLoading={isLoadingBanner} bannerData={bannerlist} />
        <div className="cmn-content-body news-wrapper">
          <div className="container">
            Event content....
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
