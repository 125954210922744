export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const DATA_SUCCESSED = 'DATA_SUCCESSED';
export const DATA_FAILED = 'DATA_FAILED';
//Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
//Profile
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const OPENING_HOURS_UPDATE = 'OPENING_HOURS_UPDATE';
//Arenas
export const ARENA_SUCCESS = 'ARENA_SUCCESS';
export const ARENA_VIEW = 'ARENA_VIEW';
//Athletes
export const ATHLETE_LIST = 'ATHLETE_LIST';
export const ATHLETE_SUCCESS = 'ATHLETE_SUCCESS';
export const ATHLETE_VIEW = 'ATHLETE_VIEW';
export const ATHLETE_COUNT = 'ATHLETE_COUNT';
export const ATHLETE_RANKING = 'ATHLETE_RANKING';
export const ATHLETE_TROPHY_LIST = 'ATHLETE_TROPHY_LIST';
export const TROPHY_TOURNAMENT_LIST = 'TROPHY_TOURNAMENT_LIST';

//Tournaments
export const TOURNAMENT_LIST = 'TOURNAMENT_LIST';
export const TOURNAMENT_FAIL = 'TOURNAMENT_FAIL';
export const TOURNAMENT_VIEW = 'TOURNAMENT_VIEW';
export const TOURNAMENT_ADD = 'TOURNAMENT_ADD';
export const TOURNAMENT_EDIT = 'TOURNAMENT_EDIT';
export const TOURNAMENT_DELETE = 'TOURNAMENT_DELETE';
export const TEAM_CREATE = 'TEAM_CREATE';
export const GROUP_PHASE_STANDING = 'GROUP_PHASE_STANDING';
export const GROUP_PHASE_MATCHES_BRACKET = 'GROUP_PHASE_MATCHES_BRACKET';
export const START_TOURNAMENT = 'START_TOURNAMENT';
export const GROUP_PHASE_UPDATE_MATCH = 'GROUP_PHASE_UPDATE_MATCH';

export const DOUBLE_ELIMINATION_MATCHES_BRACKET =
  'DOUBLE_ELIMINATION_MATCHES_BRACKET';
export const DOUBLE_ELIMINATION_UPDATE_MATCH =
  'DOUBLE_ELIMINATION_UPDATE_MATCH';
export const FINAL_MATCH_LIST = 'FINAL_MATCH_LIST';
export const AVAILABLE_ATHLETE_LIST = 'AVAILABLE_ATHLETE_LIST';

export const TOURNAMENT_WINNER_DETAILS = 'TOURNAMENT_WINNER_DETAILS';

//CMS
export const NEWS_LIST = 'NEWS_LIST';
export const NEWS_VIEW = 'NEWS_VIEW';
export const RANKING_LIST = 'RANKING_LIST';
export const MALE_RANKING_LIST = 'MALE_RANKING_LIST';
export const FEMALE_RANKING_LIST = 'FEMALE_RANKING_LIST';
export const TERMS_CONDITIONS = 'TERMS_CONDITIONS';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const HELP_LIST = 'HELP_LIST';
export const ABOUT_US = 'ABOUT_US';
export const CONTACT_US = 'CONTACT_US';
export const CONTACT_INFO = 'CONTACT_INFO';
export const ARENAS_LIST = 'ARENAS_LIST';
export const ARENAS_VIEW = 'ARENAS_VIEW';
export const UPCOMING_TOURNAMENT = 'UPCOMING_TOURNAMENT';
export const PREVIOUS_TOURNAMENT = 'PREVIOUS_TOURNAMENT';
export const ARENA_TOURNAMENT_LIST = 'ARENA_TOURNAMENT_LIST';
export const TOURNAMENT_DETAILS = 'TOURNAMENT_DETAILS';
//Common
export const DUOS_SUCCESS = 'DUOS_SUCCESS';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const TYPES_SUCCESS = 'TYPES_SUCCESS';
export const TEAM_SUCCESS = 'TEAM_SUCCESS';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const RECIEVED_NOTIFICATION = 'RECIEVED_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATION = 'CLEAR_ALL_NOTIFICATION';
//Payment
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_AMOUNT = 'PAYMENT_AMOUNT';

//
export const GET_DASHBOARD = 'GET_DASHBOARD';

export const CURRENT_PAGE = 'CURRENT_PAGE';
export const SEARCH_FIELD = 'SEARCH_FIELD';
export const STATE_FIELD = 'STATE_FIELD';
export const CITY_FIELD = 'CITY_FIELD';
export const GET_BANNER = 'GET_BANNER';
