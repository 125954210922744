import React from 'react';
import './index.scss';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';
import noImg from '../../../../assets/images/no-image.jpg';
import {BannerPlaceholder} from '../../../../components/skeleton-placeholder';

const options = {
  items: 1,
  rewind: true,
  autoplay: false,
  dots: false,
  autoHeight: true,
  navText: [
    "<i class='fa-solid fa-arrow-left'></i>",
    "<i class='fa-solid fa-arrow-right'></i>",
  ],
  navContainerClass: 'container banner-slider-nav',
  navClass: ['prev', 'next'],
};

const BannerBar = ({bannerData, isLoading}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const bannerList =
    bannerData.length > 1
      ? bannerData.filter(item => item.type != 'default')
      : bannerData;

  console.log('=====bannerList.length > 1========>', bannerList.length > 1);
  return (
    <>
      {isLoading ? (
        <BannerPlaceholder />
      ) : (
        <section className="homeBanner champBnr inner-banner-wrap">
          {bannerData && bannerData.length > 0 ? (
            <OwlCarousel
              className="owl-theme custom-banner-owl-theme"
              items={1}
              rewind={true}
              autoplay={false}
              dots={false}
              autoHeight={true}
              navText={[
                "<i class='fa-solid fa-arrow-left'></i>",
                "<i class='fa-solid fa-arrow-right'></i>",
              ]}
              navContainerClass="container banner-slider-nav"
              navClass={['prev', 'next']}
              nav={bannerList.length > 1}>
              {bannerList.map(item => {
                return (
                  <div className="container h-100" key={item.id}>
                    <div className="row banner-row h-100">
                      <div className="col-md-6 d-flex align-items-center">
                        <div className="bnrRgt">
                          <h3>{item?.title}</h3>
                          <h1>{item?.sub_title}</h1>
                          <h5>{item?.description}</h5>
                          {item.link && (
                            <button
                              className="commonButton commonBtnbordr mt-3"
                              onClick={() => window.open(item.link, '_blank')}>
                              {t('Know More')}&nbsp;&nbsp;
                              <i className="fa-solid fa-arrow-right"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 champImg">
                        <img
                          src={
                            item?.pic_url
                              ? process.env.REACT_APP_IMGBASE_URL +
                                item?.pic_url
                              : noImg
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            <p className="no-banner-found text-center py-4 mb-0">
              No banner found.
            </p>
          )}
        </section>
      )}
    </>
  );
};
export default BannerBar;
