
import { fetchBanners } from "../services/cms.service";
import { 
    fetchCategoriesList,
    fetchClearAllNotification,
    fetchDuosList, 
    fetchNotificationList, 
    fetchTeamList, 
    fetchTypesList
} from "../services/data.service";
import { 
    clearMessage, 
    getbannerAction, 
    handleCategoriesSuccess, 
    handleClearAllNotification, 
    handleDuosSuccess, 
    handleFetchDataFailed, 
    handleNotificationsSuccess, 
    handleTeamSuccess, 
    handleTypesSuccess, 
    startLoading, 
    stopLoading
} from "./Actions";

export const getDuosList = () => (dispatch)=>{
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchDuosList().then(
        (data) => {
            if(!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleDuosSuccess(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getCategoriesList = () => (dispatch)=>{
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchCategoriesList().then(
        (data) => {
            if(!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleCategoriesSuccess(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getTypesList = () => (dispatch)=>{
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchTypesList().then(
        (data) => {
            if(!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleTypesSuccess(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getTeamList = () => (dispatch)=>{
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchTeamList().then(
        (data) => {
            if(!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleTeamSuccess(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getNotificationList = () => (dispatch)=>{
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchNotificationList().then(
        (data) => {
            if(!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleNotificationsSuccess(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const clearAllNotification = () => (dispatch)=>{
    // dispatch(startLoading());
    dispatch(clearMessage());
    return fetchClearAllNotification().then(
        (data) => {
            if(!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleClearAllNotification(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getBanners = (page) => dispatch => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchBanners(page).then(
      data => {
        if (!data) return dispatch(stopLoading());
        if (data?.success) {
          dispatch(getbannerAction(data));
          dispatch(stopLoading());
        } else {
          dispatch(handleFetchDataFailed(data.message));
          dispatch(stopLoading());
        }
        return Promise.resolve();
      },
      error => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(stopLoading());
        dispatch(dispatch(handleFetchDataFailed(message)));
        return Promise.reject(error);
      },
    );
  };