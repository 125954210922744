import './index.scss';
import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logo from '../../../../assets/images/logo.svg';
import noUser from '../../../../assets/images/no-user.jpg';
import { useDispatch, useSelector } from 'react-redux';
import Logout from '../../../auth/logout';
import { Dropdown } from 'react-bootstrap';
import LanguageDropdown from '../../../../components/language-dropdown';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from '../../../../actions/Auth';

const Header = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [scrolltopdata, setscrolltopdata] = useState('');
    const [logoutModal, setLogoutModal] = useState(false);
    const { isLoggedIn, user } = useSelector(state => state.Auth);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 15) {
                setscrolltopdata('');
            } else {
                setscrolltopdata('scrolled');
            }
        });
        if (isLoggedIn) dispatch(getUserProfile());
    }, [dispatch, isLoggedIn]);

    return (
        <>
            <div className={scrolltopdata ? "langBg langBgcolor" : "langBg"}>
                <div className='container'>
                    <LanguageDropdown />
                </div>
            </div>
            <div className={scrolltopdata ? "stickyHead nav-wrap" : "nav-wrap"}>
                <Navbar collapseOnSelect expand="lg" className="navigationBg">
                    <Container>
                        <Navbar.Brand><Link to="/"><Image src={logo} /></Link></Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav" className="navbarResponsive">
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Nav>
                                <NavLink to="/arenas">{t("Arenas")}</NavLink>
                                <NavLink to="/championships">{t("Championships")}</NavLink>
                                <NavLink to="/ranking">{t("Ranking")}</NavLink>
                                <NavLink to="/events">{t("Events")}</NavLink>
                                <NavLink to="/news">{t("News")}</NavLink>
                            </Nav>
                        </Navbar.Collapse>
                        {!user && !isLoggedIn ? (
                            <Nav className="respNav">
                                <button className='commonButton hollowBtn1 ms-3' onClick={() => navigate('/login')}><i className="fa-regular fa-lock"></i> <span>{t("Login")}</span></button>
                                {/* <button className='commonButton hollowBtn1 ms-3' onClick={() => navigate('/register')}><i className="fa-light fa-user-plus"></i> <span>{t("Register")}</span></button> */}
                                <Navbar.Toggle className='ms-2' aria-controls="responsive-navbar-nav" />
                            </Nav>
                        ) : (user && isLoggedIn ?
                            <div className="header-user-nav">
                            <Dropdown className="header-user-dropdown">
                                <Dropdown.Toggle variant="">
                                    {user.profile_url === null || user.profile_url === "" || user.profile_url === undefined ?
                                        (<span className="user-img">
                                            <img src={noUser} alt='No user' />
                                        </span>) :
                                        (<span className="user-img">
                                            <img src={process.env.REACT_APP_IMGBASE_URL + user.profile_url} alt='No user' />
                                        </span>)}
                                    <span className="user-name">{user.name}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => navigate('/my-profile')}><i className="fa-regular fa-user"></i> {t("My Profile")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => navigate('/dashboard')}><i className="fa-regular fa-gauge"></i> {t("Dashboard")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setLogoutModal(true)}><i className="fa-regular fa-right-from-bracket"></i> {t("Logout")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Navbar.Toggle className='ms-2' aria-controls="responsive-navbar-nav" />
                            </div>
                            : null
                        )}
                    </Container>
                </Navbar>
            </div>
            <Logout logoutModal={logoutModal} setLogoutModal={setLogoutModal} />
        </>
    )
}

export default Header;