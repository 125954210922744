import React from 'react';
import './index.scss';

export const ArenaListPlaceholder = () => {
  return Array.from({ length: 25 }, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>

      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
      </td>
    </tr>
  ));
};

export const AthleteListPlaceholder = () => {
  return Array.from({ length: 25 }, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
      </td>
    </tr>
  ));
};

export const TournamentListPlaceholder = () => {
  return Array.from({ length: 25 }, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>

      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
      </td>
    </tr>
  ));
};
export const TrophyTournamentListPlaceholder = () => {
  return Array.from({ length: 8 }, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>

      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>

      </td>
    </tr>
  ));
};

export const NewsListPlaceholder = () => {
  return Array.from({ length: 25 }, (_, index) => (
    <tr key={index} className="card-text placeholder-glow">
      <td>
        <span className="placeholder placeholder-sm sp-img-sty"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm rounded w-25"></span>
      </td>
      <td>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
        <span className="placeholder placeholder-sm sp-btn-sty"></span>
      </td>
    </tr>
  ));
};

export const FrontEndPlaceholder = () => {
  return (
    <div className="row">
      {Array.from({ length: 16 }, (_, index) => (
        <div key={index} className="col-md-6 col-lg-3 py-3 placeholder-glow">
          <div className="placeHolderCard">
            <span className="placeholder placeholder-sm card-img-sty height-240"></span>
            <div className='placeHolderBody d-flex flex-column'>
              <span className="placeholder placeholder-sm w-50 rounded"></span>
              <span className="placeholder placeholder-xs mt-2 w-25 rounded"></span>
              <span className="placeholder placeholder-sm mt-3 placeholder-btn"></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const ChampionshipPlaceholder = () => {
  return (
    <div className="placeHolderCard placeholder-glow">
      <span className="placeholder placeholder-sm card-img-sty height-320"></span>
      <div className='placeHolderBody d-flex flex-column'>
        <span className="placeholder placeholder-sm w-50 rounded"></span>
        <span className="placeholder placeholder-xs mt-2 w-25 rounded"></span>
      </div>
    </div>
  );
};

export const RankingPlaceholder = () => {
  return (
    <div className="row">
      {Array.from({ length: 20 }, (_, index) => (
        <div key={index} className="col-md-6 col-lg-3 py-3 placeholder-glow">
          <div className="ranking-item ranking-placeholder-sty">
            <span className="placeholder placeholder-sm ranking-item-img"></span>
            <div className='ranking-item-info w-100'>
              <span className="placeholder placeholder-md mt-1 w-25 rounded"></span>
              <span className="placeholder placeholder-xs mt-2 w-50 rounded"></span>
              <span className="placeholder placeholder-sm mt-3 w-75 rounded"></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export const BannerPlaceholder = () => {
  return (
    <section className='homeBanner champBnr inner-banner-wrap placeholder-banner-wrap placeholder-glow'>
      <div className='container h-100'>
        <div className='row banner-row h-100'>
          <div className='col-md-6 d-flex align-items-center'>
            <div className='bnrRgt w-100'>
              <span className="placeholder placeholder-md d-block col-5 rounded"></span>
              <span className="placeholder placeholder-lg mt-4 d-block col-7 rounded"></span>
              <span className="placeholder placeholder-md mt-4 d-block col-4 rounded"></span>
              <span className="placeholder placeholder-sm mt-4 placeholder-banner-btn"></span>
            </div>
          </div>
          <div className='col-md-6 champImg'>
            <span className="placeholder placeholder-sm placeholder-banner-img w-100"></span>
          </div>
        </div>
      </div>
    </section>
  )
}

export const ChampionCardPlaceholder = ({ number }) => {
  return (
    <>
      {Array.from({ length: number }, (_, index) => (
        <div key={index} className="col-lg-4 col-md-6 py-2 placeholder-glow c-card-placeholder">
          <div className="championCard">
            <div className="chamCardTop">
              <span className="champDate placeholder-bg placeholder placeholder-md d-block rounded"></span>
              <div className="champTxt w-100">
                <span className="placeholder placeholder-md placeholder-bg d-block col-7 rounded"></span>
                <span className="placeholder mt-2 placeholder-md placeholder-bg d-block col-5 rounded"></span>
              </div>
            </div>
            <div className="chamCardBtm">
              <span className="placeholder placeholder-sm d-block col-3 placeholder-bg rounded"></span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export const ProfileCardPlaceholder = () => {
  return (
    <>
      <div className='pc-placeholder placeholder-glow'>
        <div className='pc-placeholder-img placeholder rounded-circle placeholder-bg'></div>
        <div className='pc-placeholder-content'>
          <span className="placeholder-bg placeholder placeholder-md col-3 d-block rounded"></span>
          <span className="placeholder-bg placeholder mt-3 placeholder-sm col-2 d-block rounded"></span>
        </div>
      </div>
    </>
  );
}

